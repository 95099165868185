import { useEffect, useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  styled,
} from '@mui/material';
// hooks
import { useResponsive } from '../../hooks/use-responsive';
import useEnvironment from '../../utils/useEnvironment';
// components
import Logo from '../../components/logo';
import { NavSectionVertical } from '../../components/nav-section';
import Scrollbar from '../../components/scrollbar';
import { usePathname } from '../../routes/hooks';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { VERSION_NUMBER } from '../../utils/VersionUtils';

// ----------------------------------------------------------------------

const EnvironmentBox = styled(List)(({ theme }) => ({
  paddingBottom: '2rem',
}));

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  const envSettings = useEnvironment();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const buildVersion = useMemo(
    () =>
      envSettings.text && (
        <Tooltip title={<>myRotra Admin v{VERSION_NUMBER}</>}>
          <span>myRotra Admin v{VERSION_NUMBER}</span>
        </Tooltip>
      ),
    [envSettings.text],
  );

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

        <NavSectionVertical
          data={navData}
          config={{
            currentRole: 'admin',
          }}
        />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
      {envSettings.color && (
        <EnvironmentBox
          sx={{
            backgroundColor: envSettings.color,
          }}
        >
          <Divider />
          <List>
            <ListItem>
              <ListItemText
                secondary={<>{buildVersion}</>}
                primary={envSettings.text}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: 'black',
                    fontWeight: 500,
                    fontSize: 16,
                  },
                  '& .MuiListItemText-secondary': {
                    color: 'black',
                    opacity: 0.7,
                  },
                }}
              />
            </ListItem>
          </List>
        </EnvironmentBox>
      )}
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
