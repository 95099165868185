import { Navigate, useRoutes } from "react-router-dom";
import { dashboardRoutes } from "./dashboard";
import { mainRoutes } from "./main";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to={"/dashboard"} replace />,
    },

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
