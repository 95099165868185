import { useNavigate } from 'react-router-dom';
// Material-UI imports

// MSAL imports
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CustomNavigationClient } from './utils/NavigationClient';

// Sample app imports
import { MotionLazy } from './components/animate/motion-lazy';
import ProgressBar from './components/progress-bar/progress-bar';
import { SettingsDrawer, SettingsProvider } from './components/settings';
import UnAuthenticatedRedirect from './layouts/auth/UnAuthenticatedRedirect';
import Router from './routes/sections';
import ThemeProvider from './theme';

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <SettingsProvider
        defaultSettings={{
          themeSize: 'large',
          themeMode: 'dark', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'orange', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          <MotionLazy>
            <SettingsDrawer />
            <ProgressBar />
            <UnAuthenticatedRedirect>
              <>
                <Router />
              </>
            </UnAuthenticatedRedirect>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </MsalProvider>
  );
}

export default App;
