// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  dashboard: {
    root: ROOTS.DASHBOARD,
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      details: (id: string) => `${ROOTS.DASHBOARD}/users/${id}`,
    },
    clients: {
      root: `${ROOTS.DASHBOARD}/clients`,
      details: (id: string) => `${ROOTS.DASHBOARD}/clients/${id}`,
    },
    events: {
      root: `${ROOTS.DASHBOARD}/events`,
      details: (id: string) => `${ROOTS.DASHBOARD}/events/${id}`,
    },
    bookings: {
      root: `${ROOTS.DASHBOARD}/bookings`,
      details: (id: string, client_id: string) =>
        `${ROOTS.DASHBOARD}/bookings/${id}/${client_id}`,
    },
    shipments: {
      root: `${ROOTS.DASHBOARD}/shipments`,
      details: (id: string, client_id: string) =>
        `${ROOTS.DASHBOARD}/shipments/${id}/${client_id}`,
    },
    contracts: {
      root: `${ROOTS.DASHBOARD}/contracts`,
      details: (id: string, client_id: string) =>
        `${ROOTS.DASHBOARD}/contracts/${id}/${client_id}`,
    },
    quotations: {
      root: `${ROOTS.DASHBOARD}/quotations`,
      details: (id: string, client_id: string) =>
        `${ROOTS.DASHBOARD}/quotations/${id}/${client_id}`,
    },
    quoteRequests: {
      root: `${ROOTS.DASHBOARD}/quoteRequests`,
      details: (id: string, client_id: string) =>
        `${ROOTS.DASHBOARD}/quoteRequests/${id}/${client_id}`,
    },
    emissionReports: {
      root: `${ROOTS.DASHBOARD}/emission-reports`,
      details: (id: string, client_id: string) =>
        `${ROOTS.DASHBOARD}/emission-reports/${id}/${client_id}`,
    },
    releaseNotes: {
      root: `${ROOTS.DASHBOARD}/release-notes`,
      details: (id: string) =>
        `${ROOTS.DASHBOARD}/release-notes/${id}`,
      new: `${ROOTS.DASHBOARD}/release-notes/new`,
    },
  },
};
