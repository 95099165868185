import { useMemo } from 'react';

enum Environment {
  DEV = 'dev',
  TEST = 'test',
  LOCAL = 'local',
  PREPROD = 'preprod',
  PROD = 'prod',
  UNKNOWN = 'unknown',
}

const settings: {
  [key: string]: { color: string; text: string };
} = {
  [Environment.DEV]: {
    color: '#07b9ff',
    text: 'Environment: DEV',
  },
  [Environment.LOCAL]: {
    color: '#ff9800',
    text: 'Environment: LOCAL',
  },
  [Environment.TEST]: {
    color: '#07ffff',
    text: 'Environment: TEST',
  },
  [Environment.PREPROD]: {
    color: '#ff9800',
    text: 'Environment: PREPROD',
  },
  [Environment.PROD]: {
    color: '',
    text: '',
  },
  [Environment.UNKNOWN]: {
    color: '',
    text: '',
  },
};

export default function useEnvironment() {
  const envSettings = useMemo(() => {
    let selectedSetting = null;
    if (process.env.VITE_OTAP_ENVIRONMENT) {
      selectedSetting = settings[process.env.VITE_OTAP_ENVIRONMENT];
    } else {
      throw new Error();
    }

    return selectedSetting;
  }, []);

  return envSettings;
}
