import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";

type PropType = {
  children: React.ReactNode;
};

export default function UnAuthenticatedRedirect({ children }: PropType) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (!isAuthenticated && inProgress === "none") {
      instance.loginRedirect();
    }
  }, [instance, isAuthenticated, inProgress]);

  return <>{isAuthenticated && <div>{children}</div>}</>;
}
