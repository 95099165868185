import {
  Configuration,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.VITE_AZURE_AD_CLIENTID || "Unknown AD:ClientID",
    authority: process.env.VITE_AZURE_AD_AUTHORITY || "Unknown AD:Authority",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

export function useAccessToken() {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.VITE_AZURE_AD_CLIENTID}/Access`],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((error) => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then((response) => {
              setAccessToken(response.accessToken);
            });
          }
        });
    }
  }, [accounts, instance]);

  return accessToken;
}
