import { forwardRef } from "react";
// @mui
import Box, { BoxProps } from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
// routes
import { RouterLink } from "../../routes/components";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          margin: 0,
          height: 60,
          display: "inline-flex",
          textAlign: "center",
          width: "100%",
          padding: "0px 32px",
          marginTop: 2,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.98 58.23">
          <title>logo</title>
          <path
            fill="white"
            d="M75.49,71.23c14.56,0,28.08-4.83,36.17-12.92,4.79-4.79,7.32-10.39,7.32-16.2s-2.53-11.41-7.32-16.2C103.57,17.83,90,13,75.49,13,51.51,13,32,26.06,32,42.11c0,5.81,2.53,11.41,7.32,16.2,8.09,8.09,21.61,12.92,36.17,12.92"
            transform="translate(-32 -13)"
          />
          <path
            fill="#ffb700"
            d="M33.79,42.12c0-5.32,2.35-10.49,6.8-14.93,7.76-7.76,20.81-12.39,34.9-12.39s27.14,4.63,34.9,12.39c4.45,4.45,6.8,9.61,6.8,14.93,0,15.07-18.71,27.32-41.7,27.32-14.1,0-27.14-4.63-34.9-12.39-4.45-4.45-6.8-9.61-6.8-14.93"
            transform="translate(-32 -13)"
          />
          <path
            d="M45.85,37h0.06a5.12,5.12,0,0,1,4.76-2.61h1v5a4.24,4.24,0,0,0-2.21-.49c-2.9,0-3.62,1.92-3.62,4.45v5.94H40.63V34.54h5.22V37Z"
            transform="translate(-32 -13)"
          />
          <path
            d="M70.27,41.92c0,5-4.13,7.83-8.87,7.83s-8.87-2.81-8.87-7.83,4.13-7.87,8.87-7.87,8.87,2.84,8.87,7.86M58,41.91a3.36,3.36,0,1,0,3.36-3.36A3.36,3.36,0,0,0,58,41.91"
            transform="translate(-32 -13)"
          />
          <path
            d="M77.82,49.27H72.6V38.85H70.91v-4.3H72.6V30.13h5.22v4.42h1.69v4.3H77.82V49.27Z"
            transform="translate(-32 -13)"
          />
          <path
            d="M86.48,37h0.06a5.11,5.11,0,0,1,4.76-2.61h1v5a4.23,4.23,0,0,0-2.21-.49c-2.9,0-3.62,1.92-3.62,4.45v5.94H81.25V34.54h5.23V37Z"
            transform="translate(-32 -13)"
          />
          <path
            d="M110.35,49.27h-5.22V47.63h-0.06a5.17,5.17,0,0,1-4.45,2.12,7.57,7.57,0,0,1-7.44-7.7q0-.08,0-0.16c0-4.22,3-7.83,7.38-7.83A6,6,0,0,1,105.13,36V34.55h5.22V49.27ZM98.58,41.92a3.36,3.36,0,1,0,3.3-3.3,3.36,3.36,0,0,0-3.3,3.3"
            transform="translate(-32 -13)"
          />
          <path
            d="M62.71,23.77a0.35,0.35,0,0,0-.22-0.27,1.82,1.82,0,0,0-.63-0.2,0.87,0.87,0,0,0-.47-0.85,0.35,0.35,0,0,0-.27.22,1.84,1.84,0,0,0-.19.63,1.83,1.83,0,0,0-.63.2,0.28,0.28,0,0,0,0,.55,1.81,1.81,0,0,0,.63.2,1.84,1.84,0,0,0,.19.63,0.28,0.28,0,0,0,.55,0,1.79,1.79,0,0,0,.2-0.63,0.87,0.87,0,0,0,.85-0.47"
            transform="translate(-32 -13)"
          />
          <path
            d="M54.74,27.82A1,1,0,0,1,55,27.15a2.31,2.31,0,0,1,3,0,1,1,0,0,1,.29.68,1.61,1.61,0,0,1-1.81,1.24A2.1,2.1,0,0,1,55,28.5a1,1,0,0,1-.29-0.68"
            transform="translate(-32 -13)"
          />
          <path
            d="M59.18,27.31a1.21,1.21,0,0,1,.36-0.84,2.86,2.86,0,0,1,3.74,0,1.22,1.22,0,0,1,.36.84c0,0.85-1,1.53-2.23,1.53a2.61,2.61,0,0,1-1.87-.7,1.21,1.21,0,0,1-.36-0.84"
            transform="translate(-32 -13)"
          />
          <path
            d="M64.44,27.82a1,1,0,0,1,.29-0.68,2.31,2.31,0,0,1,3,0,1,1,0,0,1,.29.68,1.61,1.61,0,0,1-1.81,1.24,2.11,2.11,0,0,1-1.51-.56,1,1,0,0,1-.29-0.68"
            transform="translate(-32 -13)"
          />
          <rect x="25.02" y="17.12" width="8.76" height="1.71" />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
