import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
// routes
import { useRouter } from '../../routes/hooks';
// components
import { useAccount, useMsal } from '@azure/msal-react';
import { WidthNormalTwoTone, WidthWideTwoTone } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { varHover } from '../../components/animate';
import CustomPopover, { usePopover } from '../../components/custom-popover';
import { useSettingsContext } from '../../components/settings';

const getInitials = (name: string) => {
  return name
    .split(' ')
    .slice(0, 2)
    .map((val) => `${val}`[0])
    .join('')
    .toUpperCase();
};

export default function AccountPopover() {
  const router = useRouter();
  const settings = useSettingsContext();
  const { instance } = useMsal();
  const account = useAccount();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      instance.logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleSize = () => {
    settings.onUpdate(
      'themeSize',
      settings.themeSize === 'large' ? 'small' : 'large',
    );
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          alt={account?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {getInitials(account?.name || '')}
        </Avatar>
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 250, p: 0 }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account?.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account?.username}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleToggleSize}>
            {settings.themeSize === 'large' ? (
              <WidthWideTwoTone />
            ) : (
              <WidthNormalTwoTone />
            )}
            Toggle Size
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
